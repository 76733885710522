@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	background-color: $white;
	color: $black;
	padding-top: 7rem;
	position: relative;

	@include sm-down {
		padding-top: 4rem;
	}
}

.header_container {
	display: flex;
	justify-content: center;

	top: 2rem;
	left: 0;
	right: 0;

	position: absolute;
	width: 100%;

	//opacity: 0;
	//transform: translateY(100px);

	@include sm-down {
		top: 2rem;
	}
}

.container header {
	text-align: center;

	margin: auto;

	padding: 5rem 4rem;

	border-radius: 0.5rem;

	@include sm-down {
		padding: 2rem 1.25rem;
	}

	.eyebrow {
		display: block;
		color: rgba(0, 0, 0, 0.5);
	}

	h2 {
		max-width: 17ch;
		margin-inline: auto;
	}

	.subheader {
		color: #363636;
		max-width: 48ch;
		margin-inline: auto;
	}
}

.container video {
	object-fit: cover;
	@include xs-only {
		object-position: 25%;
	}
	width: 100%;
	height: 100%;
	min-height: 40rem;
	display: block;

	max-width: 100rem;
	margin-inline: auto;

	pointer-events: none;
}

.video_button {
	margin-top: $spacing-x-large-desktop;
	svg {
		margin-inline-end: 0.5rem;
	}
}
