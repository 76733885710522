@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	background-color: #f1f2f4;
	color: $black;

	padding: 7rem 4rem;

	@include sm-down {
		padding: 4rem 1.25rem;
	}
}

.container header {
	text-align: center;

	margin-bottom: 4rem;

	.eyebrow {
		display: block;
		color: rgba(0, 0, 0, 0.5);
	}

	h2 {
		max-width: 20ch;
		margin-inline: auto;
	}

	.subheader {
		color: #363636;
		max-width: 48ch;
		margin-inline: auto;
	}

	a {
		margin-top: 2.5rem;
	}
}

.image_container {
	position: relative;
	max-width: 90rem;
	margin-inline: auto;

	border-radius: 0.5rem;
	contain: paint;

	display: flex;
	justify-content: center;
	align-items: center;

	img,
	video {
		object-position: center;
		object-fit: cover;
	}

	img {
		min-height: 20rem;
		max-height: 45rem;
	}

	video {
		max-height: 45rem;
		min-height: 20rem;

		@include sm-down {
			max-height: 25rem;
		}
	}
}

.temp_widget {
	position: absolute !important;

	left: 20%;
	bottom: 4rem;

	@include sm-down {
		bottom: 2rem;
		left: 5%;
	}
}

.warm {
	position: absolute !important;

	right: 20%;
	bottom: 6rem;

	@include sm-down {
		bottom: 2rem;
		right: 5%;
	}
}

.benefits_list {
	list-style: none;
	padding: 0;
	margin: 0;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: start;
	justify-content: center;

	max-width: 90rem;
	margin-inline: auto;

	text-align: center;

	@include sm-down {
		grid-template-columns: 1fr;
	}

	li {
		border-radius: 0.5rem;

		padding: 3rem 5rem;

		@include sm-down {
			margin-top: 2rem;
			padding: 3rem 1.5rem;
			width: 100%;
			background: $white;
		}
	}
}

.benefits_list svg {
	margin-bottom: 1.25rem;
	width: 3rem;
	height: 3rem;
}

.benefit_title {
	margin-inline: auto;
	@include sm-down {
		max-width: 12ch;
	}
}

.benefits_list p {
	color: #363636;

	max-width: 38ch;
	margin-inline: auto;
}
