@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	position: relative;
	color: $white;
}

.content {
	position: absolute;
	top: 0;
	left: 50%;
	width: 50%;

	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;

	padding: 10rem 4rem 10rem 0;

	z-index: 2;

	@include sm-down {
		align-items: center;
		left: 0;
		width: 100%;

		padding: 10rem 2rem;
		justify-content: flex-start;
	}
}

.hero_image {
	img {
		height: 100svh;
		max-height: 70rem;
		object-position: 32%;
	}
}

.content {
	@include sm-down {
		text-align: center;
	}
}

.eyebrow {
	margin-bottom: 1rem;
}

.content h1 {
	max-width: 22ch;
}

.content header .subheader {
	@include sm-down {
		max-width: 30ch;
		margin-inline: auto;
	}
}

.benefits {
	display: grid;
	grid-template-columns: repeat(4, 9.5rem);
	list-style: none;
	padding: 0;
	gap: 1rem;

	margin: 2.5rem 0;

	@media (max-width: 1400px) {
		grid-template-columns: repeat(4, 7.5rem);
		gap: 1rem;
	}

	@include sm-down {
		grid-template-columns: repeat(4, 5.5rem);
		gap: 1rem;

		margin: 2rem 0;
	}

	@include xs-only {
		grid-template-columns: repeat(4, 5rem);
		gap: 0.5rem;
	}
}

.benefit {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

	text-align: center;

	gap: 0.75rem;

	padding: 1rem 1.25rem;

	border-radius: 0.5rem;
	box-shadow: hsla(0, 0%, 100%, 0.1) 1.25px 0 1px 0 inset, hsla(0, 0%, 100%, 0.1) -1.5px 0 1px 0 inset, hsla(0, 0%, 100%, 0.1) 0 -1.5px 1px inset, hsl(0, 0%, 100%, 0.5) 0 1px 0.5px inset;
	background: rgba(255, 255, 255, 0.1);
	backdrop-filter: blur(10px);

	@include sm-down {
		gap: 0.5rem;

		padding: 1rem;

		svg {
			width: 1rem;
			height: 1rem;
		}
	}

	div {
		flex: 1;
		display: flex;
		align-items: center;
	}

	// Required to hide the element until the animation is started
	visibility: hidden;
}

.disclaimer {
	color: rgba(255, 255, 255, 0.7);

	margin-top: 0.5rem;
}

.cta_container {
	visibility: hidden;
}
