@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	@include section-padding;

	background-color: #fff;
	color: $black;

	contain: paint;
}

.header {
	text-align: center;

	max-width: 1440px;
	margin: 0 auto;
	margin-bottom: 5rem;

	@include xs-only {
		text-align: left;
		margin-bottom: 2rem;
	}

	.heading {
		margin-inline: auto;
		max-width: 25ch;

		@include xs-only {
			max-width: none;
		}
	}
}

.heading span:not(:first-child) {
	display: block;
	opacity: 0.5;
}

.header_content_with_cta {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	gap: 16px;

	text-align: left;

	@include sm-down {
		flex-direction: column;
		align-items: flex-start;
	}

	.heading {
		margin: 0;
		max-width: 25ch;

		@include xs-only {
			max-width: none;
		}
	}
}

.grid {
	display: grid;
	max-width: 1440px;
	margin: 0 auto;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	gap: 20px;
}

.review_item {
	display: flex;
	flex-direction: column;
	border-radius: 12px;
	background-color: $background-off-white;
	margin: 0;

	.review_item_img {
		flex: 1;
		max-height: 250px;
		width: 100%;
		aspect-ratio: 1 / 1;
		object-fit: cover !important;
		border-radius: 12px;
		object-position: top center;
	}

	.review_item_text {
		padding: 28px;
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
	}

	.review_item_author {
		margin-top: 0.5rem;
	}

	.review_item_subtitle {
		margin-top: auto;
		color: $text-grey-dark;

		@include sm-down {
			margin-top: 0;
		}
	}

	@include sm-down {
		height: fit-content;
	}
}

.slider_wrapper {
	width: 100%;
}

.media_stack {
	width: 100%;
	height: 100%;
	position: relative;
	padding: 0;

	display: grid;
	grid-template-areas: 'center';

	list-style: none;
	margin: 0;

	> * {
		grid-area: center;
	}
}

.media_item {
	position: relative;
	height: 100%;
	width: 85vw;

	transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out, scale 0.5s ease-in-out;

	display: flex;
	justify-content: center;
	align-items: stretch;
	margin: 0;
	border-right: 5vw solid transparent;

	> button {
		text-align: left;
	}
}

.stars {
	display: flex;
	max-width: 1440px;
	margin-inline: auto;

	justify-content: flex-start;
	align-items: center;

	flex-wrap: wrap;
	gap: 12px;

	margin-block: 32px 80px;

	@include sm-down {
		margin-block: 20px 30px;

		justify-content: flex-start;
	}
}

.dots_container {
	display: flex;
	gap: 4px;

	margin-block-start: 16px;

	.dot {
		width: 8px;
		height: 8px;

		border-radius: 50%;
		background: black;
		opacity: 0.5;

		transition: opacity 0.25s ease-in-out;

		&[data-active='true'] {
			opacity: 1;
		}
	}
}
