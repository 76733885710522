@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	&[data-theme='dark'] {
		background: #141414;
		color: $white;

		.eyebrow {
			color: rgba(255, 255, 255, 0.5);
		}

		.description {
			color: rgba(255, 255, 255, 0.7);
		}
	}

	&[data-theme='light'] {
		background: $background-grey-light;
		color: $text-black;

		.eyebrow {
			color: $text-grey-dark;
		}

		.description {
			color: $text-grey-dark;
		}
	}

	&[data-theme='white'] {
		background: $background-white;
		color: $text-black;

		.eyebrow {
			color: $text-grey-dark;
		}

		.description {
			color: $text-grey-dark;
		}
	}

	padding: 7rem 4rem;

	@include sm-down {
		padding: 4rem 1.5rem;
	}
}

.container header {
	text-align: center;

	h2 {
		max-width: 20ch;
		margin-inline: auto;
	}

	margin-bottom: 4rem;
}

.items {
	list-style: none;
	padding: 0;
	margin: 0;

	max-width: 90rem;
	margin-inline: auto;

	display: flex;
	flex-direction: column;
	gap: 10rem;

	@include sm-down {
		gap: 4rem;
	}
}

.item {
	display: grid;
	grid-template-columns: 1fr;

	column-gap: 6.25rem;

	align-items: center;

	row-gap: 2rem;

	@include md-up {
		&:nth-child(even) {
			.image_container {
				order: 1;
			}
		}

		grid-template-columns: 1fr 1fr;
	}

	a {
		margin-top: 2.5rem;
	}
}

.title {
	max-width: 22ch;
}

.description {
	max-width: 40ch;
}

.disclaimer {
	color: #a6a6a6;
}

.image_container {
	img {
		max-height: 35rem;
	}
}
