@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';

.container {
	background-color: $background-off-white;
	color: $black;

	position: relative;

	display: grid;
	grid-template-columns: 1fr 1fr;

	&[data-theme='white'] {
		color: $black;
		background-color: $white;
	}

	&[data-theme='dark'] {
		color: $white;
		background-color: #121212;

		path {
			stroke: $white;
		}

		.text_link {
			color: $white;
		}

		.item {
			border-bottom: 1px solid rgba(255, 255, 255, 0.7);

			&:first-child {
				border-top: 1px solid rgba(255, 255, 255, 0.7);
			}

			p {
				color: $text-grey-light;
			}
		}

		.eyebrow {
			color: rgba(255, 255, 255, 0.5);
		}
	}

	@include sm-down {
		grid-template-columns: 1fr;
	}
}

.image_container {
	position: relative;
	width: min(50vw, 60rem);

	background-color: $black;

	img {
		height: 55rem;
		object-position: center;
	}

	@include sm-down {
		position: static;
		width: 100%;

		.disclaimer {
			display: none;
		}

		margin-bottom: 4rem;

		img {
			object-position: center;

			max-height: 27rem;
			height: 100%;
		}
	}
}

.cta_container {
	display: flex;
	gap: 2rem;
	align-items: center;

	@include sm-down {
		display: none;
	}
}

.disclaimer {
	position: absolute;

	bottom: 0;
	left: 0;
	right: 0;

	text-align: center;

	padding: 4rem 3rem;

	color: $text-white;

	@include sm-down {
		position: static;
		text-align: left;
		padding: 3rem 0;

		margin: 0 2rem;
	}
}

.content_container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 3.75rem;

	max-width: 50rem;

	padding: 4rem;

	.disclaimer {
		display: none;
	}

	@include sm-down {
		padding: 0;

		gap: 2rem;

		margin: auto;

		.disclaimer {
			display: block;
		}
	}
}

.eyebrow {
	color: $text-grey-dark;
}

.content_container header {
	h2 {
		max-width: 22ch;

		sup {
			font-size: 0.5em;
		}
	}

	@include sm-down {
		padding: 0 2rem;

		left: 0;
		right: 0;

		margin: auto;
		width: 100%;

		text-align: left;

		margin-bottom: 2rem;
	}
}

.items {
	list-style: none;
	padding: 0;
	margin: 0;

	position: relative;
	z-index: 2;

	@include sm-down {
		margin: 0 2rem;
	}
}

.item {
	display: flex;
	flex-direction: column;
	text-align: left;

	padding: 1.5rem 0;

	border-bottom: 1px solid $black;

	&:first-child {
		border-top: 1px solid $black;
	}

	&[data-open='true'] {
		svg {
			transform: rotate(-180deg);
		}
	}

	svg {
		transition: all 0.25s ease-out;
	}

	button {
		span {
			width: 100%;
		}
		width: 100%;
		justify-items: normal;
	}
}

.item h3 {
	display: flex;
	justify-content: space-between;
	align-items: center;

	text-align: left;

	transition: all 0.25s ease-out;
}

.item p {
	text-align: left;

	transition: all 0.25s ease-out;
	overflow: hidden;

	color: $text-grey-dark;
}
