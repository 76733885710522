@import 'styles/layout-tokens';
@import 'styles/colors-2024';
@import 'styles/mixins/media-queries';
@import 'styles/mixins/fonts-2024';

.container {
	padding: $spacing-xxx-large-desktop $spacing-x-large-desktop;

	@include sm-down {
		padding: $spacing-xxx-large-mobile 0;
	}

	background-color: #fff;
	color: $black;

	.header_container {
		max-width: 1400px;
		margin: 0 auto;

		> header {
			text-align: left;
			max-width: 700px;
			padding: 0;
			margin-bottom: 2.5rem;

			@include sm-down {
				text-align: left;
				margin-bottom: 2rem;
				padding: 0 1.25rem;
			}
		}

		.eyebrow {
			color: $text-grey-dark;
			font-size: 11px;
			font-weight: 500;
		}

		.subheader {
			color: $text-grey-dark;
		}
	}
}

.grid {
	display: grid;
	max-width: 1400px;
	margin: 0 auto;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	gap: 20px;
}

.review_item {
	display: flex;
	flex-direction: column;
	border-radius: 12px;
	overflow: hidden;
	background-color: $white;

	.review_item_img {
		flex: 1;
	}

	.review_item_img > img {
		width: 100%;
		height: 100%;
		max-height: 330px;
		object-fit: cover !important;
		border-radius: 12px;
	}

	.review_item_text {
		padding: 1rem 0 0;
		flex: 1;
	}

	.review_item_author {
		margin-top: 1.7rem;
	}

	.review_item_subtitle {
		color: $text-grey-dark;
		margin-top: 0.5rem;
	}
}

.slider_wrapper {
	width: 100%;
	height: 440px;
	padding: 0 20px;
	overflow: hidden;
	position: relative;
}

.media_stack {
	width: 100%;
	height: 100%;
	position: relative;
	padding: 0;

	display: grid;
	grid-template-areas: 'center';

	list-style: none;
	margin: 0;
	overflow: hidden;

	> * {
		grid-area: center;
	}
}

.media_item {
	position: absolute;
	height: 100%;
	width: 100%;

	transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out, scale 0.5s ease-in-out;

	display: flex;
	justify-content: center;
	align-items: stretch;
	left: 0;
	margin: 0;

	> button {
		text-align: left;
	}
}

.nav {
	padding: 2rem 20px 0;
	display: flex;
	position: relative;
	margin-bottom: 20px;
	justify-content: space-between;

	> ul {
		position: static;
		transform: none;
		margin: 0;
	}

	.nav_buttons_wrapper {
		display: flex;
		gap: 10px;

		> button {
			position: static;
			flex: 1;
		}
	}
}

.thumbnails {
	position: relative;
	bottom: 0;
	width: auto !important;
	margin: 0 !important;
}

.nav_button {
	background: transparent;
	border: none;
	height: 100%;
	padding: 0;
	cursor: pointer;

	width: 1.25rem;

	&.prev_slide {
		background-image: url('https://eightsleep.imgix.net/icons_arrow_left.svg?v=1650776727');
		background-position: center;
		background-repeat: no-repeat;
	}

	&.next_slide {
		background-image: url('https://eightsleep.imgix.net/icons_arrow_right.svg?v=1650776727');
		background-position: center;
		background-repeat: no-repeat;
	}
}
